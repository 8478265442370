import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import ExcelJS from 'exceljs';
import { API, Storage } from "aws-amplify";


const MonthlyTimesheetReport = ({ newBucketRegistor }) => {
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const [reportData, setReportData] = useState([]);
    const [excelData, setExcelData] = useState(null);
    const [iframeKey, setIframeKey] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState("");
    const [selectedClient, setSelectedClient] = useState("");
    const { bucketName, regionName } = newBucketRegistor;

    const employeeNames = [...new Set(reportData.map(entry => {
        if (entry.timesheet && entry.timesheet.JobOrder && entry.timesheet.JobOrder.Member) {
            return `${entry.timesheet.JobOrder.Member.FirstName} ${entry.timesheet.JobOrder.Member.LastName}`;
        }
        return null;
    }).filter(name => name))];

    const clientNames = [...new Set(reportData.map(entry => {
        if (entry.timesheet && entry.timesheet.JobOrder && entry.timesheet.JobOrder.Contract.Clients.Name) {
            return entry.timesheet.JobOrder.Contract.Clients.Name;
        }
        return null;
    }).filter(name => name))];

    const handleFromDateChange = date => {
        setSelectedFromDate(new Date(date.toISOString()));

    };

    const handleToDateChange = date => {
        setSelectedToDate(new Date(date.toISOString()));
    };
    const handleEmployeeChange = event => {
        setSelectedEmployee(event.target.value);
    };

    const handleClientChange = event => {
        setSelectedClient(event.target.value);
    };



    function formatDateToUS(dateString) {        
        if (dateString == null)
            return "";
        const date = new Date(dateString);
        
        const usDateString = date.toLocaleString('en-US', { timeZone: 'America/New_York' });
        
        const usDate = new Date(usDateString);
        
        const year = usDate.getFullYear();
        const month = ('0' + (usDate.getMonth() + 1)).slice(-2); 
        const day = ('0' + usDate.getDate()).slice(-2);

        return `${year}-${month}-${day}`;
    }


    const generateReport = () => {
     
        if (!selectedFromDate || !selectedToDate) {
            setErrorMessage("Please select both From Date and To Date.");
            return;
        }

        if (selectedFromDate > selectedToDate) {
            setErrorMessage("From Date must be less than or equal to To Date.");
            return;
        }
        if (!selectedEmployee) {
            setErrorMessage("Please select Employee.")
            return;
        }
        if (!selectedClient) {
            setErrorMessage("Please select Client.")
            return;
        }
        const filteredData = reportData.filter(entry => {
            const entryDate = new Date(new Date(entry.Date).toLocaleDateString('en-US', { timeZone: "utc" }));
            const employeeName = entry.timesheet && entry.timesheet.JobOrder && entry.timesheet.JobOrder.Member ?
                `${entry.timesheet.JobOrder.Member.FirstName} ${entry.timesheet.JobOrder.Member.LastName}` : null;
            const clientName = entry.timesheet && entry.timesheet.JobOrder && entry.timesheet.JobOrder.Contract.Clients ?
                entry.timesheet.JobOrder.Contract.Clients.Name : null;
            return (
                (!selectedFromDate || entryDate >= selectedFromDate) &&
                (!selectedToDate || entryDate <= selectedToDate) &&
                (!selectedEmployee || employeeName === selectedEmployee) &&
                (!selectedClient || clientName == selectedClient)
            );
        });
        const baseUrl = window.location.origin;
        console.log("FilterDtaa", filteredData);
        const fromDateText = selectedFromDate ? selectedFromDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : 'Start Date';
        const toDateText = selectedToDate ? selectedToDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : 'End Date';
        const dateRange = `From ${fromDateText} to ${toDateText}`;

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Monthly Timesheet Report");

        worksheet.addRow(["Monthly Timesheet Report"]).font = { bold: true, size: 16 };
        worksheet.mergeCells('A1:J1');
        worksheet.addRow([]);
        worksheet.addRow(["Filters:", dateRange]);
        worksheet.addRow([]);

        const columnHeaderRow = worksheet.addRow(['Date', 'Day', 'Hours', 'Description', 'Category', 'Approved by', 'Approved Date','Approve Timesheet']);
        columnHeaderRow.eachCell(cell => {
            cell.font = { bold: true };
        });

        let totalHours = 0;
        let ptoHours = 0;
        let holidayHours = 0;
        let sickDayHours = 0;
        let unpaidLeaveHours = 0;
        let workername;
        let clientName;
        let supervisor;
        let contact;
        let puchaseOrder;
        let title;
        let contractNumber;
        let workOrder;
        
       

        filteredData.forEach(entry => {
            if (entry.timesheet && entry.timesheet.JobOrder) {
                debugger;
                workername = `${entry.timesheet.JobOrder.Member.FirstName} ${entry.timesheet.JobOrder.Member.LastName}`;
                clientName = (entry.timesheet.JobOrder.ClientBillTo != null ? entry.timesheet.JobOrder.ClientBillTo : "");
                contractNumber = (entry.timesheet.JobOrder.ContractNumber != null ? entry.timesheet.JobOrder.ContractNumber : "");
                workOrder = (entry.timesheet.JobOrder.WorkOrder != null ? entry.timesheet.JobOrder.WorkOrder : "");
                const supervisorContact = entry.timesheet.JobOrder.Contract.Clients.Contacts.find(contact => contact.PrimaryContact === true);

                supervisor = supervisorContact ?
                    `${supervisorContact.FirstName} ${supervisorContact.LastName}` :
                    entry.timesheet.JobOrder.Contract.Clients.Contacts.length > 0 ?
                        `${entry.timesheet.JobOrder.Contract.Clients.Contacts[0].FirstName} ${entry.timesheet.JobOrder.Contract.Clients.Contacts[0].LastName}` :
                        "";
                contact = supervisorContact ?
                    supervisorContact.Phone:
                    entry.timesheet.JobOrder.Contract.Clients.Contacts.length > 0 ?
                     entry.timesheet.JobOrder.Contract.Clients.Contacts[0].Phone :
                        "";
                  title = (entry.timesheet.JobOrder.Title) ? entry.timesheet.JobOrder.Title : "";
                puchaseOrder = (entry.timesheet.JobOrder.PurchaseOrder) ? entry.timesheet.JobOrder.PurchaseOrder : "";



                const timesheetId = entry.timesheet.ItemId; 
                const contactId = supervisorContact ?
                    supervisorContact.ItemId :
                    entry.timesheet.JobOrder.Contract.Clients.Contacts.length > 0 ?
                        entry.timesheet.JobOrder.Contract.Clients.Contacts[0].ItemId :
                        "";
                const approveUrl = `${baseUrl}/public/approve-timesheets?timesheets=${timesheetId}&contact=${contactId}`;

                
                const approveButton = {
                    text: 'Approve',
                    hyperlink: approveUrl,
                   
                };


                const row = worksheet.addRow([
                    entry.Date,
                    new Date(entry.Date).toLocaleDateString('en-US', { timeZone: "utc", weekday: 'long' }),
                    entry.Hours,
                    entry.Details,
                    entry.Category,
                    entry.timesheet.ApprovedBy,
                    formatDateToUS(entry.timesheet.ApprovalDate),
                    approveButton.text
                ]);
                const approveCell = row.getCell(8); 
                approveCell.value = { text: approveButton.text, hyperlink: approveUrl };
                approveCell.font = { color: { argb: '0000FF' }, underline: true };

                if (entry.Details) {
                    const descriptionCell = row.getCell(4);
                    const lowerCaseDetails = entry.Details.toLowerCase();

                    if (lowerCaseDetails.includes(":pto")) {
                        descriptionCell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: '32b96f' } 
                        };
                    } else if (lowerCaseDetails.includes(":holiday")) {
                        descriptionCell.fill = {
                            type: 'pattern', 
                            pattern: 'solid',
                            fgColor: { argb: '0b80e7' } 
                        };
                    } else if (lowerCaseDetails.includes(":sick day")) {
                        descriptionCell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FF800080' } 
                        };
                    } else if (lowerCaseDetails.includes(":unpaid leave")) {
                        descriptionCell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFA500' } 
                        };
                    }
                }
                totalHours += entry.Hours;
                
            }
        });

        worksheet.addRow([]);
        worksheet.addRow(['',  'Total Hours:', totalHours]);
        worksheet.addRow([]);
        worksheet.addRow([]);
        const employeeNameRow = worksheet.addRow(['', '', 'Employee Name:', workername]);
        employeeNameRow.getCell(3).font = { bold: true }; 
        const supervisorNameRow = worksheet.addRow(['', '', 'Supervisor Name:', supervisor]);
        supervisorNameRow.getCell(3).font = { bold: true }; 
        const clientNameRow = worksheet.addRow(['', '', 'Client / Bill To:', clientName]);
        clientNameRow.getCell(3).font = { bold: true }; 
        const contactRow = worksheet.addRow(['', '', 'Contact:', contact]);  
        contactRow.getCell(3).font = { bold: true }; 
        const contractNameRow = worksheet.addRow(['', '', 'Contract:', contractNumber]);   
        contractNameRow.getCell(3).font = { bold: true }; 
        const titleNameRow = worksheet.addRow(['', '', 'Title:', title]);
        titleNameRow.getCell(3).font = { bold: true }; 
        const purchaseOrderRow = worksheet.addRow(['', '', 'Purchase Order:', puchaseOrder]);
        purchaseOrderRow.getCell(3).font = { bold: true }; 
        const workOrderRow = worksheet.addRow(['', '', 'Work Order:', workOrder]);
        workOrderRow.getCell(3).font = { bold: true }; 
        worksheet.addRow([]);
        worksheet.addRow([]);
        worksheet.addRow(['', '', '', 'Signature of Supervisor']).font = { bold: true };
        worksheet.addRow([]);
        worksheet.addRow([]);
        worksheet.addRow([]);
        worksheet.addRow(['', '', '', 'Signature of Employee']).font = { bold: true };
        worksheet.addRow([]);
        worksheet.getColumn('A').width = 20;
        worksheet.getColumn('B').width = 20;
        worksheet.getColumn('C').width = 15;
        worksheet.getColumn('D').width = 30;
        worksheet.getColumn('E').width = 10;
        worksheet.getColumn('F').width = 30;
        worksheet.getColumn('G').width = 30;
        worksheet.getColumn('H').width = 15;
        worksheet.getColumn('I').width = 15;


        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const uploadStorageFile = async (file) => {
                let response = await Storage.put("MonthlyTimesheetReport.xlsx", file, {
                    bucket: bucketName,
                    region: regionName,
                    contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                console.log(response);
                const timestamp = Date.now();
                setExcelData(`https://teckpert-platform-web-staging.s3.us-east-2.amazonaws.com/public/MonthlyTimesheetReport.xlsx?timestamp=${timestamp}`);
            }
            uploadStorageFile(blob);
            setIframeKey(prevKey => prevKey + 1);
        });
    };

    const downloadReport = () => {
        const timestamp = new Date().getTime();
        const url = `https://teckpert-platform-web-staging.s3.us-east-2.amazonaws.com/public/MonthlyTimesheetReport.xlsx?timestamp=${timestamp}`;

        const link = document.createElement('a');
        link.href = url;
        link.download = `MonthlyTimesheetReport.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    useEffect(() => {
        const today = new Date();
        const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1); 
        const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0); 
        
        setSelectedFromDate(firstDayOfLastMonth);
        setSelectedToDate(lastDayOfLastMonth);
    }, []);

    useEffect(() => {      
        API.graphql({
            query: `{                
                Timesheets {
                    ItemId
                    JobOrderId
                    ApprovedBy
                    ApprovalDate
                    JobOrder {
                        BillableRate
                        Rate
                        ContractId
                        Title
                        PurchaseOrder
                        ContractNumber
                        ClientBillTo
                        WorkOrder
                        ItemId
                        MemberId
                        Member {
                            FirstName
                            ItemId
                            LastName
                        }
                        Contract {
                            ItemId
                            Name
                            ClientId                           
                            Clients {
                                ItemId
                                Name
                                Contacts {
                                  ItemId
                                  ClientId
                                  FirstName
                                  LastName
                                  Mobile
                                  Phone
                                  PrimaryContact
                                }
                             }
                        }
                    }
                }
                TimesheetEntries(order_by: {Date: asc}) {
                    Date
                    Hours
                    Category
                    Details                        
                    ItemId
                    TimesheetId
                }
            }
        `,
        }).then(({ data }) => {
           
            const combinedData = data.TimesheetEntries.map(entry => {               
                const timesheet = data.Timesheets.find(sheet => sheet.ItemId === entry.TimesheetId);
                return { ...entry, timesheet };
            });
            setReportData(combinedData);
            console.error('Combined  data:', combinedData);
        }).catch(error => {
            console.error('Error fetching data:', error);

        });
    }, []);

    return (
        <div>
            <div className="flex items-center ml-5 lg:mt-5 mt-20 flex-row flex-wrap  lg:flex-wrap xl:flex-wrap">
                <div className="sm:col-span-3 w-full lg:w-auto mr-3">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                      Employeee
                    </label>

                    <select
                        value={selectedEmployee}
                        onChange={handleEmployeeChange}
                        className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md
                                  border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                  text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                    >
                        <option value="">Select Employee</option>
                        {employeeNames.map((name, index) => (
                            <option key={index} value={name}>{name}</option>
                        ))}
                    </select>
                </div>
                <div className="sm:col-span-3 w-full lg:w-auto mr-3">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                        Client
                    </label>

                    <select
                        value={selectedClient}
                        onChange={handleClientChange}
                        className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md
                                  border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                  text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                    >
                        <option value="">Select Client</option>
                        {clientNames.map((name, index) => (
                            <option key={index} value={name}>{name}</option>
                        ))}
                    </select>
                </div>
                <div className="sm:col-span-3 w-full lg:w-auto">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                        Date From
                    </label>
                    <div className="mt-1">
                        <DatePicker
                            className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md
                                  border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                  text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                            selected={selectedFromDate}
                            onChange={handleFromDateChange}
                            dateFormat="MM-dd-yyyy"
                            fixedHeight
                        />
                    </div>
                </div>
                <div className="col-span-3 lg:ml-5 ml-0 w-full lg:w-auto">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                        Date To
                    </label>
                    <div className="mt-1">
                        <DatePicker
                            className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md
                                  border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                  text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                            selected={selectedToDate}
                            onChange={handleToDateChange}
                            dateFormat="MM-dd-yyyy"
                            fixedHeight
                        />
                    </div>
                </div>
                <div className="sm:col-span-3 lg:ml-5 ml-0 mt-4 w-full lg:w-auto">
                    <button
                        onClick={() => generateReport()}
                        type="button"
                        className="rounded-md w-full border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm
                               focus:outline-none sm:w-auto hover:opacity-90"
                    >
                        Generate Report
                    </button>
                </div>
                <div className="sm:col-span-3 lg:ml-5 ml-0 mt-4 w-full lg:w-auto">
                    {excelData && (
                        <button
                            onClick={downloadReport}
                            type="button"
                            className="rounded-md w-full border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm
                               focus:outline-none sm:w-auto hover:opacity-90"
                        >
                            Download Report
                        </button>
                    )}
                </div>
            </div>
            <div className="mt-5 ml-5">
                {excelData && (
                    <iframe
                        style={{ border: "3px solid black", width: "100%" }}
                        key={iframeKey}
                        height="700"
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                            excelData
                        )}`} title="Payroll W2 Report"
                        allowfullscreen>
                    </iframe>
                )}

            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: 20, flexWrap: "wrap", justifyContent: "left", }}>
                {errorMessage && <div className="error-message" style={{ color: 'red' }}>{errorMessage}</div>}
            </div>
        </div>
    );
};

export default MonthlyTimesheetReport;
