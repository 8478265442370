import { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./components/Dashboard/Navbar";
import Sidebar from "./components/Dashboard/Sidebar";
import Login from "./components/Login/Login";
import Signup from "./components/Login/Signup";
import People from "./components/Dashboard/People/People";
import Client from "./components/Dashboard/Client/Client";
import ClientDetail from "./components/Dashboard/Client/ClientDetail/ClientDetail";
import Opportunities from "./components/Dashboard/Opportunities/Opportunities";
import Contract from "./components/Dashboard/Contracts/Contracts";
import ContractDetail from "./components/Dashboard/Contracts/ContractDetail/ContractDetail";
import Timesheet from "./components/Dashboard/Timesheets/Timesheets";
import Invoice from "./components/Dashboard/Invoice/Invoice";
import Tools from "./components/Dashboard/Tools/Tools";
import Users from "./components/Dashboard/Users/Users";
import Courses from "./components/Dashboard/Courses/Courses";
import Profile from "./components/Dashboard/People/Profile/Profile";
import OpportunityDetails from "./components/Dashboard/Opportunities/Details/OpportunityDetails";
import Resources from "./components/Dashboard/Tools/Resources/Resources";
import MessageLog from "./components/Dashboard/Tools/Messages/MessageLog";
import EmailTemplates from "./components/Dashboard/Tools/Email Templates/EmailTemplates";
import CreateEmailTemplate from "./components/Dashboard/Tools/Email Templates/CreateEmailTemplate";
import UpdateEmailTemplate from "./components/Dashboard/Tools/Email Templates/UpdateEmailTemplate";
import JobPortals from "./components/Dashboard/Tools/Job Portals/JobPortals";
import JobPortalPage from "./components/Dashboard/Tools/Job Portals/Portal Page/JobPortalPage";
import JobDetails from "./components/Dashboard/Tools/Job Portals/Portal Page/JobDetails";
import AppQuestionnaire from "./components/Dashboard/Tools/Questionnaire.js/AppQuestionnaires";
import Questionnnaire from "./components/Dashboard/Tools/Questionnaire.js/Questionnaire/Questionnaire";
import InterviewGuides from "./components/Dashboard/Tools/Interview Guides/InterviewGuides";
import InterviewGuideView from "./components/Dashboard/Tools/Interview Guides/InterviewGuideView";
import Workflows from "./components/Dashboard/Tools/Workflows/Workflows";
import Stages from "./components/Dashboard/Tools/Workflows/Stages";
import Checklists from "./components/Dashboard/Tools/Checklists/Checklists";
import ChecklistItems from "./components/Dashboard/Tools/Checklists/ChecklistItems";
import AdminProfile from "./components/Dashboard/AdminProfile";
import CompanyProfile from "./components/Dashboard/CompanyProfile";
import "./App.css";
import { Amplify, Auth, API } from "aws-amplify";
import Set from "./components/Login/Set";
import Loading from "./components/utils/Loading";
import { ApproveTimesheets } from "./components/Public/ApproveTimesheets/ApproveTimesheets";
import Mytimesheet from "./components/Dashboard/Mytimesheet/Mytimesheet";
import TimeEntry from "./components/Dashboard/TimeEntry/TimeEntry";
import Reports from "./components/Dashboard/Reports/Reports";
import PayrollW2Report from "./components/Dashboard/Reports/PayrollW2Report";
import MonthlyTimesheetReport from "./components/Dashboard/Reports/MonthlyTimesheetReport";
const environment =
  process.env.APP_ENVIRONMENT === "prod"
    ? {
      //production
      endpoint: "https://lambda.teckpertplatform.com",
      region: "us-east-2",
      identityPoolId: "us-east-2:4ca7cbc2-1601-4e4e-afea-6bb597fcd6e5",
      userPoolId: "us-east-2_RpBlv03q7",
      userPoolWebClientId: "7sn16h3l4prrn0b6ubbrmsjd8d",
      bucket: "teckpert-platform-docs",
      graphqlEndpoint: "https://api.teckpertplatform.com/v1/graphql",
    }
    : {
      //staging
      endpoint: "https://lambda-staging.teckpertplatform.com",
      region: "us-east-2",
      identityPoolId: "us-east-2:5b6c6c8c-0817-4267-b91a-363fec7a8fe7",
      userPoolId: "us-east-2_fPFUn0S7N",
      userPoolWebClientId: "4fr1k3sopv3e4ldvkp0dhl88dp",
      bucket: "teckpert-platform-staging-docs",
      graphqlEndpoint: "https://api-staging.teckpertplatform.com/v1/graphql",
        };

        //change configuration in Cognito
        //Cognito_teckpertPlatformstagingAuth_Role
        //s3 - acess
        //"arn:aws:s3:::teckpert-platform-web-staging/*"
       const newBucketRegistor = { bucketName: "teckpert-platform-web-staging", regionName: "us-east-2" };


Amplify.configure({
  Auth: {
    identityPoolId: environment.identityPoolId,
    region: environment.region,
    identityPoolRegion: environment.region,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId,
    mandatorySignIn: false,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  API: {
    graphql_endpoint: environment.graphqlEndpoint,
    graphql_headers: async () => ({
      Authorization:
        "Bearer " + (await Auth.currentSession()).getIdToken().getJwtToken(),
    }),
    endpoints: [
      {
        name: "public",
        endpoint: environment.endpoint + "/public",
        region: environment.region,
      },
      {
        name: "auth",
        endpoint: environment.endpoint + "/auth",
        region: environment.region,
        custom_header: async () => ({
          Authorization:
            "Bearer " +
            (await Auth.currentSession()).getIdToken().getJwtToken(),
        }),
      },
    ],
  },
  Storage: {
    AWSS3: {
              bucket: environment.bucket,
              region: environment.region,
          },
  },
  Analytics: {
    disabled: true,
  },
});

function App() {
    // const dispatch = useDispatch();  
  const navigate = useNavigate();
  const location = useLocation();
  const [tabName, setTabName] = useState("");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [authenticated, setAuthenticated] = useState(null);
  const [selectedTimesheets, setSelectedTimesheets] = useState([]);
  const [user, setUser] = useState(null);

  const navigationlessRoutes = [
    "/login",
    "/signup",
    "/set",
    "/forgot-password",
    "/jobs/",
  ];

  useEffect(() => {
    setTabName(
      `${location.pathname[1]?.toUpperCase()}${location.pathname.slice(2, 100)}`
      );

      //console.log("sidebaar",)
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        if (location.pathname === "/") {
          if (user.attributes["custom:userType"] === "member") {
              navigate("/mytimesheets");
          } else {
            navigate("/people");
          }
          }
        
        setAuthenticated(true);
      })
      .catch(() => {
        if (location.pathname === "/") {
          navigate("/login");
        } else if (
          ![
            "/login",
            "/signup",
            "/set",
            "/forgot-password",
            "/jobs",
            "/public",
          ].find((obj) => location.pathname.startsWith(obj))
        ) {
          navigate("/login");
        }
        setAuthenticated(false);
      });
  }, [location.pathname, navigate]);

  useEffect(() => {
      if (!location.pathname.includes("/jobportals"))
       // console.log("load user data")
      API.graphql({
        query: `{
        Users {
          AccessId
          Badge
          CalendlyProfileUrl
          ContractAwards
          DateCreated
          DateUpdated
          Email
          FirstName
          ImportantDates
          HiddenColumns
          TableFilters
          ItemId
          LastName
          NewOpportunities
          Password
          Payments
          PushToken
          QBTokenDev
          QBToken
          SNS
          Timezone
          UserType
        }
      }      
    `,
      }).then(({ data }) => {
          let userData = data.Users.filter(
              (obj) =>
                  obj.ItemId ===
                  JSON.parse(localStorage.attributes)["custom:itemId"]
          )[0];//fillter a data based on a login user 
          if (userData.TableFilters === null) {
              userData.TableFilters = {
                  "people": [],
                  "contracts": [],
                  "opportunities": []
              }
          }// add a Empty filter array object if object is emty 
          setUserData(userData);
       });
  }, [location.pathname]);
    
  return (
    <div
      className={
        !location.pathname.includes("public") &
          !navigationlessRoutes.includes(location.pathname)
          ? "App overflow-x-hidden bg-gray-100"
          : ""
      }
    >
      {!location.pathname.includes("public") &
        !navigationlessRoutes.includes(location.pathname) ? (
        <Navbar setMobileMenuOpen={setMobileMenuOpen} />
      ) : (
        <div></div>
      )}

      <div
        className={
          !location.pathname.includes("public") &
            !navigationlessRoutes.includes(location.pathname)
            ? "h-screen flex overflow-x-hidden"
            : ""
        }
      >
        {!location.pathname.includes("public") &
          !navigationlessRoutes.includes(location.pathname) ? (
                      <Sidebar
                          setMobileMenuOpen={setMobileMenuOpen}
                          mobileMenuOpen={mobileMenuOpen}
                          tabName={tabName}
                          setTabName={setTabName}
                          usertype={user}
          />
        ) : (
          <div></div>
        )}

        <div
          className={
            !location.pathname.includes("public") &
            !navigationlessRoutes.includes(location.pathname)
              ? "pt-20 mx-auto w-11/12 overflow-x-hidden overflow-y-scroll"
              : ""
          }
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/set" element={<Set />} />
            <Route path="/adminprofile" element={<AdminProfile />} />
            <Route path="/companyprofile" element={<CompanyProfile />} />
            {/* PUBLIC ROUTES */}
            <Route
              path="/jobportals/public/:id"
              element={
                <JobPortalPage userData={userData} setUserData={setUserData} />
              }
            />
            <Route path="/jobs/public/:id" element={<JobDetails />} />
            {/* PROTECTED ROUTES */}
                      {user?.attributes?.["custom:userType"] !== "member" &&
              <>
                <Route
                  path="/people"
                  element={<People userData={userData} setUserData={setUserData} />}
                />
                <Route path="/people/:id" element={<Profile />} />
                <Route path="/clients/:id" element={<ClientDetail />} />
                <Route path="/clients" element={<Client />} />
                <Route
                  path="/opportunities"
                  element={
                    <Opportunities userData={userData} setUserData={setUserData} />
                  }
                />
                <Route path="/opportunities/:id" element={<OpportunityDetails />} />
                <Route
                  path="/contracts"
                  element={
                    <Contract userData={userData} setUserData={setUserData} />
                  }
                />
                <Route path="/contracts/:id" element={<ContractDetail />} />
                <Route
                  path="/timesheets"
                  element={
                    <Timesheet
                      show={true}
                      selectedTimesheets={selectedTimesheets}
                      setSelectedTimesheets={setSelectedTimesheets}
                          userData={userData}
                    />
                  }
                          />
                <Route path="/entertime" element={<TimeEntry user={userData} />} />
                <Route path="/reports/payrollW2Report" element={<PayrollW2Report newBucketRegistor={newBucketRegistor} />} />
                <Route path="/reports/monthlyTimesheetReport" element={<MonthlyTimesheetReport newBucketRegistor={newBucketRegistor} />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route path="/tools" element={<Tools />} />
                <Route path="/users" element={<Users />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/messageslog" element={<MessageLog />} />
                <Route path="/emailtemplates" element={<EmailTemplates />} />
                <Route
                  path="/emailtemplates/new"
                  element={<CreateEmailTemplate />}
                />
                <Route
                  path="/emailtemplates/:id"
                  element={<UpdateEmailTemplate />}
                />
                <Route path="/forgot-password" element={<Login />} />
                <Route
                  path="/jobportals"
                  element={
                    <JobPortals userData={userData} setUserData={setUserData} />
                  }
                />
                
                <Route path="/appquestionnaire" element={<AppQuestionnaire />} />
                <Route path="/appquestionnaire/:id" element={<Questionnnaire />} />
                <Route path="/interviewguides" element={<InterviewGuides />} />
                <Route
                  path="/interviewguides/:id"
                  element={<InterviewGuideView />}
                />
                <Route path="/workflows" element={<Workflows />} />
                <Route path="/workflows/:id" element={<Stages />} />
                <Route path="/checklists" element={<Checklists />} />
                <Route path="/checklists/:id" element={<ChecklistItems />} />               
                <Route
                  path="/public/approve-timesheets"
                  element={<ApproveTimesheets />}
                />
                          </>}
                      {user?.attributes?.["custom:userType"] === "member" &&
                          <>
                          <Route path="/mytimesheets" element={<Mytimesheet user={userData}/>} />
                          <Route path="/entertime" element={<TimeEntry user={userData}  />} />
                          <Route path="/entertime/:Id" element={<TimeEntry user={userData} />} />
                          </>}
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
