import { useState, useEffect } from "react";
import { PlusCircleIcon, PencilIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";
import { formatDate } from "../../../../utils/FormatDate";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import AddWorkerModal from "../Modals/AddWorkerModal";
import UpdateWorkerModal from "../Modals/UpdateWorkerModal";
import { MinusCircleIcon, DocumentTextIcon } from "@heroicons/react/outline";

const Workers = ({
    tableData,
    setTableData,
    contractData,
    documentUrl,
    setConfirmButtonMessage,
}) => {
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [selected, setSelected] = useState([]);
    const [member, setMember] = useState([]);

    useEffect(() => {
        API.graphql({
            query: `{
        Members {
          FirstName
          ItemId
          LastName
        }
      }      
    `,
        }).then(({ data }) => {
            setMember(data.Members.filter((obj) => obj.FirstName));
            console.log("tableData", tableData);
        });
    }, []);
    function changeBackground(e) {
        e.target.style.background = 'Black';
    }
    return (
        <div>
            <AddWorkerModal
                contractData={contractData}
                open={modalOpen}
                setOpen={setModalOpen}
                tableData={tableData}
                setTableData={setTableData}
                member={member}
                setConfirmButtonMessage={setConfirmButtonMessage}
            />
            <div className="my-5 mx-8 p-6 bg-white rounded-lg border shadow-md hover:bg-gray-100 ">
                <div className="flex justify-between">
                    <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
                        Workers
                    </h4>
                    <div
                        onClick={() => setModalOpen(!modalOpen)}
                        className="flex flex-none mr-5"
                    >
                        <PlusCircleIcon
                            className="h-8 w-8 cursor-pointer mr-3 teckpert-text-green"
                            aria-hidden="true"
                        />
                    </div>
                </div>

                {/* Table Section */}
                <div className="mx-5 flex flex-col p-3">
                    <div className="sm:-mx-6 lg:-mx-8">
                        <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8 overflow-x-auto">
                            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg min-w-full divide-y divide-gray-300 border">
                                    <thead className="bg-gray-100">
                                        <tr>
                                            {[
                                                "      ",
                                                "Worker Name",
                                                "Title",
                                                "Hours",
                                                "Start Date",
                                                "End Date",
                                                "Term",
                                                "Billable Rate",
                                                "Worker Rate",
                                                "Work Type",
                                                "Time Approval",
                                                "Auto Send",
                                                "Purchase Order",
                                                "Job Code",
                                                "Funding Source",
                                                "Contract Number",
                                                "Client/Bill To",
                                                "Work Order",
                                                "",
                                            ].map((obj, i) => (
                                                <th
                                                    key={i}
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    {obj}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white ml-2">
                                        {tableData?.map((obj, i) => {
                                            let index = member.findIndex(
                                                (x) => x.ItemId === obj.MemberId
                                            );
                                            return (
                                                <tr key={obj.ItemId + i}>
                                                    <td>
                                                        <div>
                                                            
                                                            <span data-tip={obj.Tooltipdata ? `${obj.Tooltipdata}` : null}
                                                               
                                                                data-place="TOP" data-type="success"><div className="flex items-center justify-center hover:text-red-500">
                                                                <MinusCircleIcon
                                                                        className={`${obj.Difference_In_Days <= 60 && obj.Difference_In_Days >= 0
                                                                        ? "bg-red-500 text-red-500 "
                                                                        
                                                                            : "bg-gray-400text-gray-400 w-[30px] flex-none"
                                                                        }  rounded-full h-3 w-3`}
                                                                    aria-hidden="true"
                                                                />
                                                            </div></span>
                                                            {obj.Tooltipdata && <ReactTooltip place="top" type="success" effect="solid" />}

                                                        </div>
                                                    </td>
                                                    <td
                                                        onClick={() => {
                                                            navigate("/people/" + obj.MemberId);
                                                        }}
                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold font-semibold text-gray-900 sm:pl-6 
                            teckpert-text-green cursor-pointer"
                                                    >
                                                        <a>
                                                            {member[index]?.FirstName +
                                                                " " +
                                                                member[index]?.LastName}
                                                        </a>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj?.Title}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj.Hours ? obj.Hours : "-"}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj.StartDate ? formatDate(obj?.StartDate) : "-"}
                                                    </td>
                                                    <td className=" whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj.EndDate ? formatDate(obj?.EndDate) : "-"}
                                                    </td>
                                                    <td className=" whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {Math.floor(
                                                            (new Date(obj?.EndDate).getTime() -
                                                                new Date(obj?.StartDate).getTime()) /
                                                            (1000 * 3600 * 24)
                                                        )}
                                                        &nbsp; Days
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj.BillableRate ? "$" + obj.BillableRate : "-"}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj.Rate ? "$" + obj.Rate : "-"}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj.Remote ? obj.Remote : "-"}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj.TimeApproval == "1" ? "Yes" : "No"}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj.AutoSend ? "Yes" : "No"}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj?.PurchaseOrder}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj?.JobCode}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj?.FundingSource}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj?.ContractNumber}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj?.ClientBillTo}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        {obj?.WorkOrder}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                        <PencilIcon
                                                            onClick={() => {
                                                                setEditModalOpen(!editModalOpen);
                                                                setSelectedId(obj.ItemId);
                                                                setSelected(obj);
                                                            }}
                                                            className="h-5 w-5 cursor-pointer ml-3 text-gray-300 hover:teckpert-text-green"
                                                            aria-hidden="true"
                                                        />
                                                        {selectedId && (
                                                            <UpdateWorkerModal
                                                                open={editModalOpen}
                                                                setOpen={setEditModalOpen}
                                                                tempData={selected}
                                                                setTempData={setSelected}
                                                                setTableData={setTableData}
                                                                member={member}
                                                                setConfirmButtonMessage={
                                                                    setConfirmButtonMessage
                                                                }
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Workers;
